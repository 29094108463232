import axios, { AxiosRequestConfig } from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { customHeaderPrefix } from 'common/constants/customHeaderPrefix';
import { getDebugMetadataAsHeaders } from 'common/logger/debugMetadata';
import { logError, logRequest } from 'common/logger/logRequest';
import { CommonQueryParams } from '../model/CommonQueryParams';
import { Environment } from '../model/Environment';
import { getQueryParams } from '../utils/getQueryParams';
import { ClientApp } from 'common/model/ClientApp';

export function initializeAxios(token: string, clientApp = ClientApp.Dealer, authTokenType?: string) {
  const environment = getEnvironment();
  if (!validEnvironment(clientApp, environment)) {
    throw new Error(`Invalid environment: ${environment}`);
  }
  addBaseUrlInterceptor(clientApp, environment);
  addAuthInterceptor(clientApp, token, authTokenType);
  addResponseInterceptor();
  addStartTimeInterceptor();
  addCustomHeadersInterceptor();
  addRequestIdInterceptor();
}

export function getEnvironment() {
  return getQueryParams<CommonQueryParams>(document.location.search).env || process.env.REACT_APP_CAI_ENV || 'int1';
}

export const ENV_CONSTANTS: Record<
  Environment,
  [
    {
      key: string;
      value: string;
    }
  ]
> = {
  local: [{ key: 'PRICING_LOCATION_CODE', value: 'QLM1' }],
  int1: [{ key: 'PRICING_LOCATION_CODE', value: 'QLM1' }],
  int5: [{ key: 'PRICING_LOCATION_CODE', value: 'QLM1' }],
  uat: [{ key: 'PRICING_LOCATION_CODE', value: 'PLM1' }],
  prod: [{ key: 'PRICING_LOCATION_CODE', value: 'AAA' }]
};

function getBackendURLs(clientApp: ClientApp): Record<Environment, string> {
  if (clientApp === ClientApp.Dealer) {
    return {
      local: 'http://localhost:3000',
      int1: 'https://int1-next-inspect-plus-backend.awsgdmownernp.manheim.com',
      int5: 'https://int5-next-inspect-plus-backend.awsgdmownernp.manheim.com',
      uat: 'https://uat-next-inspect-plus-backend.awsgdmownernp.manheim.com',
      prod: 'https://prod-next-inspect-plus-backend.awsgdmowner.manheim.com'
    };
  }

  return {
    local: 'http://localhost:3000',
    int1: 'https://next-inspect-consumer-backend-int1.awsmanninp.manheim.com',
    int5: 'https://next-inspect-consumer-backend-int5.awsmanninp.manheim.com',
    uat: 'https://next-inspect-consumer-backend-uat.awsmanni.manheim.com',
    prod: 'https://next-inspect-consumer-backend-prod.awsmanni.manheim.com'
  };
}

export function validEnvironment(clientApp: ClientApp, environment: string): environment is Environment {
  return environment in getBackendURLs(clientApp);
}

function addBaseUrlInterceptor(clientApp: ClientApp, environment: Environment) {
  axios.interceptors.request.use((config: AxiosRequestConfig) => ({
    ...config,
    baseURL: getBackendURLs(clientApp)[environment]
  }));
}

function addAuthInterceptor(clientApp: ClientApp, token: string, authTokenType?: string) {
  axios.interceptors.request.use((config: AxiosRequestConfig) => {
    if (!token) {
      console.warn('Warning: attempting an api call without a token');
      return config;
    }

    if (clientApp === ClientApp.Dealer) config.headers['Authorization'] = `Bearer ${token}`;
    else {
      // We use authTkt token (or dealer token) from NIC mobile app and mashery token from HITL side.
      if (authTokenType === 'mashery') config.headers['Authorization'] = `Bearer ${token}`;
      else if (authTokenType === 'dealer') config.headers['X-Auth-Dealer-Token'] = token;
      else config.headers['authTkt'] = `${token}`;
    }

    return config;
  });
}

function addResponseInterceptor() {
  axios.interceptors.response.use(logRequest, logError);
}

function addStartTimeInterceptor() {
  axios.interceptors.request.use((config: AxiosRequestConfig) => {
    return {
      ...config,
      metadata: { startTime: new Date() } // To track request duration
    };
  });
}

function addCustomHeadersInterceptor() {
  axios.interceptors.request.use((config: AxiosRequestConfig) => {
    const requestConfig = config as AxiosRequestConfig & {
      ignoreDebugHeaders: boolean;
    };
    return {
      ...requestConfig,
      headers: {
        ...requestConfig.headers,
        ...(requestConfig.ignoreDebugHeaders ? {} : getDebugMetadataAsHeaders())
      }
    };
  });
}

function addRequestIdInterceptor() {
  axios.interceptors.request.use((config: AxiosRequestConfig) => {
    return {
      ...config,
      headers: {
        ...config.headers,
        [`${customHeaderPrefix}request-id`]: uuidv4().split('-')[0]
      }
    };
  });
}
