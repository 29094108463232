import React, { useEffect, useState } from 'react';

import { initializeAxios } from 'common/api/setup';
import { fetchExpandedDisclosureById } from 'common/api/disclosure/fetchExpandedDisclosureById';
import { ExpandedGFBResponse } from 'common/model/Disclosure/ExpandedGFBResponse';
import { getQueryParams } from 'common/utils/getQueryParams';
import { logger } from 'common/logger/logger';
import { DisclosureSubtype, DisclosureType } from 'common/model/Disclosure/Disclosure';
import { setDisclosureIdMetadata, setVinMetadata } from 'common/logger/debugMetadata';
import { getInspectionChargesDetails } from 'ewt/requests/chargesAPI/getInspectionChargesDetails';
import { InspectionChargesDetailsType } from 'ewt/requests/chargesAPI/types';
import { WebViewWindow } from 'bmw/common/model/WebViewWindow';
import { ClientApp } from 'common/model/ClientApp';
import { EWTForm } from './EWTForm';

const clientApp = ClientApp.Consumer;

type QueryParams = {
  authToken: string;
  authTokenType?: string;
  disclosureId: string;
  dealerName?: string;
};

export const EWTFormContainer: React.FC = () => {
  const [inspectionChargesDetails, setInspectionChargesDetails] = useState<InspectionChargesDetailsType>();
  const [error, setError] = useState<boolean>(false);
  const [inspectionType, setInspectionType] = useState<string>();
  const [maskedVIN, setMaskedVIN] = useState<string>('');
  const [dealerName, setDealerName] = useState<string | undefined>('');

  const reactNativeWebView = (window as Partial<WebViewWindow & typeof globalThis>).ReactNativeWebView;

  useEffect(() => {
    logger.info('tfsEwt:render');

    const { authToken, authTokenType, disclosureId, dealerName } = getQueryParams<QueryParams>();

    if (!authToken || !disclosureId) {
      logger.error('EWTContainer:initialize:invalidParams', {
        disclosureId
      });

      throw 'Invalid parameters';
    }

    setDealerName(dealerName);
    setDisclosureIdMetadata(disclosureId);

    initializeAxios(authToken, clientApp, authTokenType);

    async function initialize() {
      try {
        const expandedDisclosureResponse = await fetchExpandedDisclosureById(disclosureId);

        setMaskedVIN('XXXXXXXXX' + expandedDisclosureResponse.unit.vin.substring(9));
        setVinMetadata(expandedDisclosureResponse.unit.vin);

        expandedDisclosureResponse.responses.sort(
          (a: ExpandedGFBResponse, b: ExpandedGFBResponse) => Number(a.question.id) - Number(b.question.id)
        );

        if (
          expandedDisclosureResponse.type === DisclosureType.ThirdParty &&
          expandedDisclosureResponse.subtype === DisclosureSubtype.LeaseEnd
        ) {
          setInspectionType('End of Term Inspection');
        } else if (
          expandedDisclosureResponse.type === DisclosureType.ThirdParty &&
          expandedDisclosureResponse.subtype === DisclosureSubtype.Preterm
        ) {
          setInspectionType('Pre-Term Inspection');
        }

        const [inspectionChargesDetailsResponse] = await Promise.all([
          getInspectionChargesDetails(expandedDisclosureResponse.guid)
        ]);

        const [inspectionChargesDetails] = inspectionChargesDetailsResponse as InspectionChargesDetailsType[];

        setInspectionChargesDetails(inspectionChargesDetails);

        setError(false);
        reactNativeWebView?.postMessage(
          JSON.stringify({
            type: 'loaded',
            message: 'TFS EWT report is loaded'
          })
        );
      } catch (error) {
        reactNativeWebView?.postMessage(
          JSON.stringify({
            type: 'error',
            message: 'TFS EWT report is unavailable'
          })
        );
        logger.error('tfsEwt:initialize:error', { error });
        setError(true);
      }
    }
    initialize();
  }, [reactNativeWebView]);

  return (
    <EWTForm
      inspectionChargesDetails={inspectionChargesDetails}
      isError={error}
      inspectionType={inspectionType}
      vin={maskedVIN}
      dealerName={dealerName ?? ''}
    />
  );
};
