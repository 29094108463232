import React from 'react';
import styled from 'styled-components';

import {
  VehiclePropertiesListContainer,
  getListElements,
  ConsumerStylePropertiesListItem
} from './vehiclePropertiesListStyles';
import { commaNumber } from 'common/helpers/commaNumber';
import { formatCurrency } from 'common/helpers/formatCurrency';
import { TopLine, PoppinsHeader, BMWHeader } from '../helpers/globalStyles';

interface Props {
  totalMileageCharge: number;
  currentMileage: number | undefined;
  initialMileage: number | undefined;
  contractMileage: number | undefined;
  isConsumerInspection?: boolean;
  isPdfRendering?: boolean;
}

export const ExcessMileage: React.FC<Props> = ({
  totalMileageCharge,
  currentMileage = 0,
  initialMileage = 0,
  contractMileage = 0,
  isConsumerInspection,
  isPdfRendering
}) => {
  const milesDriven = currentMileage - initialMileage;
  const excessMileage = currentMileage - contractMileage;

  const { Title, Value } = getListElements({ isConsumerInspection, isPdfRendering });
  const ListItem = isConsumerInspection ? ConsumerStylePropertiesListItem : CustomListItem;
  const Summary = isConsumerInspection ? SummaryItem : CustomListItem;

  return (
    <ExcessMileageContainer isConsumerInspection={isConsumerInspection}>
      {isConsumerInspection ? (
        <div style={{ padding: '15px 0', width: '100%' }}>
          <ConsumerStyleTitle isPdfRendering={isPdfRendering}>Excess Mileage Charge</ConsumerStyleTitle>
        </div>
      ) : (
        <SectionHeaderContainer>
          <SectionHeaderTitle>Excess Mileage Charge</SectionHeaderTitle>
          <TotalMileageValue>{formatCurrency(totalMileageCharge)}</TotalMileageValue>
        </SectionHeaderContainer>
      )}
      <VehiclePropertiesListContainer isConsumerInspection>
        <ListItem>
          <Title>Current Mileage*</Title>
          <Value>{commaNumber(currentMileage)}</Value>
        </ListItem>
        {isConsumerInspection && (
          <ListItem>
            <Title>Miles Driven</Title>
            <Value>{commaNumber(milesDriven)}</Value>
          </ListItem>
        )}
        <ListItem>
          <Title>Miles at Inception (from Contract)</Title>
          <Value>{commaNumber(initialMileage)}</Value>
        </ListItem>
        {!isConsumerInspection && (
          <ListItem>
            <Title>Miles Driven</Title>
            <Value>{commaNumber(milesDriven)}</Value>
          </ListItem>
        )}
        <ListItem>
          <Title>Total Allowed Mileage</Title>
          <Value>{commaNumber(contractMileage)}</Value>
        </ListItem>
        <ListItem>
          <Title>Excess Mileage</Title>
          <Value>{commaNumber(excessMileage)}</Value>
        </ListItem>
        <Summary>
          <Title fontWeight="bold">Total Excess Mileage Charge</Title>
          <TotalMileageValue isConsumerInspection={isConsumerInspection} isPdfRendering={isPdfRendering}>
            {formatCurrency(totalMileageCharge)}
          </TotalMileageValue>
        </Summary>
      </VehiclePropertiesListContainer>
    </ExcessMileageContainer>
  );
};

const ExcessMileageContainer = styled.div<{
  isConsumerInspection?: boolean;
}>`
  break-inside: avoid-page;
  ${props => (props.isConsumerInspection ? TopLine : '')}
`;

const SectionHeaderContainer = styled.div`
  width: 100%;
  background-color: #eeefea;
  display: flex;
  justify-content: space-between;
  padding: 12px 10px;
  text-transform: uppercase;
`;

const SectionHeaderTitle = styled.div`
  ${BMWHeader}
`;

const ConsumerStyleTitle = styled.div<{
  isPdfRendering?: boolean;
}>`
  ${props => (props.isPdfRendering ? BMWHeader : PoppinsHeader)}
  color: black;
`;

const TotalMileageValue = styled.div<{
  isConsumerInspection?: boolean;
  isPdfRendering?: boolean;
}>`
  color: black;
  font-size: ${props => (props.isConsumerInspection ? '16px' : '19px')};
  ${props => (props.isConsumerInspection ? 'font-weight: bold;' : '')}
  ${props => (props.isConsumerInspection && !props.isPdfRendering ? '' : 'font-family: BMWGroupCondensed-Bold;')}
`;

export const SummaryItem = styled(ConsumerStylePropertiesListItem as any)`
  margin-left: auto;
  text-align: right;
`;

export const CustomListItem = styled.div<{
  isConsumerInspection?: boolean;
}>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 0px 20px 0px;
  width: 100%;

  @media print, screen and (min-width: 680px) {
    flex-direction: column;
    width: 33%;
    padding: 10px 0px 10px 0px;
    justify-content: flex-start;
  }
`;
